import { FieldProps, FieldState } from './Field';
import { VisibilityRule, VisibilityRuleState } from './Rules';

export interface StepProps {
	id: number;
	Fields: FieldProps[];
	Title: string; // (internal, not shown)
	StepType: StepType; // (Default, Redirect)
	VisibilityRules: VisibilityRule[]; // (see VISIBILITY RULES.md)
	RedirectTo: string | null; // (Used only if StepType is "Redirect")
}

export enum StepType {
	Default = 'Default',
	Redirect = 'Redirect',
}

export interface StepState {
	fields: FieldState[];
	isValid: boolean;
	isVisible: boolean;
	visibilityRulesStates: VisibilityRuleState[];
}
