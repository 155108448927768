import { useState } from 'react';

export function useInput(initialValue: string) {
	const [value, setValue] = useState(initialValue);
	const [isDirty, setDirty] = useState(false);

	const handleChange = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setValue(e.target.value);
		if (!isDirty) {
			setDirty(true);
		}
	};

	return {
		value,
		isDirty,
		onChange: handleChange,
	};
}
