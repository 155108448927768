import { useEffect, useMemo } from 'react';
import { useInput } from '../../../hooks/useInput';
import { useValidationRules } from '../../../hooks/useRule';
import { FieldComponentProps } from '../../../models/Field';
import { ValidationRule } from '../../../models/Rules';
import FieldError from '../../fieldError/fieldError';
import { Operator } from '../../../models/Operator';

const CAP_VAL_RULES: ValidationRule[] = [
	{
		Compare: 'Value',
		ErrorMessage: 'Cap invalido!',
		Operator: Operator.Regex,
		Value: '^[0-9]{5}$',
	},
];

const Cap = ({ field, dispatch }: FieldComponentProps) => {
	const cap = useInput('');

	const validation_rules = useMemo(
		() => field.ValidationRules.concat(CAP_VAL_RULES),
		[field.ValidationRules]
	);
	const { handleValidations, isValid, rulesStates } =
		useValidationRules(validation_rules);

	useEffect(() => {
		handleValidations(cap.value);
	}, [cap.value, handleValidations]);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				name: field.Name,
				isValid,
			},
		});
	}, [dispatch, field.Name, isValid]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				name: field.Name,
				value: cap.value,
				isEmpty: cap.value === '',
			},
		});
	}, [dispatch, cap.value, field.Name]);

	return (
		<div className="field-input-container">
			<label htmlFor={field.Name}>{field.Label}</label>
			<input
				className={`field__input ${
					cap.isDirty && !isValid ? 'field__input--error' : ''
				}`}
				name={field.Name}
				id={field.Name}
				type="number"
				value={cap.value}
				onChange={cap.onChange}
			/>
			<FieldError rulesStates={rulesStates} isDirty={cap.isDirty} />
		</div>
	);
};

export default Cap;
