import { useContext, useEffect, useMemo } from 'react';
import { FieldProps, FieldType as FieldTypes } from '../../models/Field';
import {
	SurveyContext,
	SurveyDispatchContext,
} from '../../context/surveyContext';
import FullName from './fields/fullName';
import Text from './fields/text';
import ButtonNext from './fields/buttonNext';
import Email from './fields/email';
import Mobile from './fields/mobile';
import Options from './fields/options';
import ProgressBar from './fields/progressBar';
import InputNumber from './fields/number';
import { useVisibilityRules } from '../../hooks/useRule';
import StaticText from './fields/staticText';
import ButtonFinish from './fields/buttonFinish';
import Cap from './fields/cap';
import Checkbox from './fields/checkbox';
import './field.css';

const Field = ({
	field,
	stepIndex,
	fieldIndex,
}: {
	field: FieldProps;
	stepIndex: number;
	fieldIndex: number;
}) => {
	const dispatch = useContext(SurveyDispatchContext);
	const context = useContext(SurveyContext);
	let fieldComponent: JSX.Element;

	const { isVisible, handleVisibility } = useVisibilityRules(
		field.VisibilityRules
	);

	const visRulesStates = useMemo(
		() => context.steps[stepIndex].fields[fieldIndex].visibilityRules,
		[context.steps, fieldIndex, stepIndex]
	);

	useEffect(() => {
		dispatch({
			action: 'update-field-visibility',
			data: {
				isVisible,
				stepIndex,
				fieldIndex,
			},
		});
	}, [dispatch, fieldIndex, isVisible, stepIndex]);

	useEffect(() => {
		handleVisibility(visRulesStates);
	}, [handleVisibility, visRulesStates]);

	switch (field.FieldType) {
		case FieldTypes.ButtonNext:
			if (!context.steps[stepIndex]?.isValid) {
				fieldComponent = <></>;
				break;
			}
			fieldComponent = (
				<ButtonNext
					key={`ButtonNext-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.FullName:
			fieldComponent = (
				<FullName
					key={`FullName-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.FreeText:
			fieldComponent = (
				<Text
					key={`Text-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.Mobile:
			fieldComponent = (
				<Mobile
					key={`Mobile-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.Checkbox:
			fieldComponent = (
				<Checkbox
					key={`CheckBoxes-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.Options:
			fieldComponent = (
				<Options
					key={`Options-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.Email:
			fieldComponent = (
				<Email
					key={`Email-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.Number:
			fieldComponent = (
				<InputNumber
					key={`Number-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.ButtonFinish:
			fieldComponent = (
				<ButtonFinish
					key={`ButtonFinish-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.StaticText:
			fieldComponent = (
				<StaticText
					key={`StaticText-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.ProgressBar:
			fieldComponent = (
				<ProgressBar
					key={`ProgressBar-${field.Name}`}
					field={field}
					dispatch={dispatch}
				/>
			);
			break;
		case FieldTypes.Cap:
			fieldComponent = (
				<Cap
					field={field}
					dispatch={dispatch}
					key={`Cap-${field.Name}`}
				/>
			);
			break;
		default:
			fieldComponent = <></>;
	}

	return <div className="field">{isVisible && fieldComponent}</div>;
};

export default Field;
