import { useState, useEffect } from 'react';
import { FieldComponentProps } from '../../../models/Field';
import { useValidationRules } from '../../../hooks/useRule';
import FieldError from '../../fieldError/fieldError';

const Checkbox = ({ field, dispatch }: FieldComponentProps) => {
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);

	const handleOnChange = (e: any) => {
		setIsDirty(true);
		setIsChecked((isChecked) => !isChecked);
	};

	const { handleValidations, isValid, rulesStates } = useValidationRules(
		field.ValidationRules
	);

	useEffect(() => {
		handleValidations(isChecked.toString());
	}, [handleValidations, isChecked]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				value: isChecked.toString(),
				name: field.Name,
				isEmpty: !isChecked,
			},
		});
	}, [dispatch, field.Name, handleValidations, isChecked]);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				isValid,
				name: field.Name,
			},
		});
	}, [dispatch, field.Name, isValid]);

	return (
		<div className="field-checkbox-container">
			<input
				className="field__checkbox"
				type="checkbox"
				checked={isChecked}
				name={field.Name}
				id={field.Name}
				onChange={handleOnChange}
			/>
			<label className="field-label" htmlFor={field.Name}>
				{field.Label}
			</label>
			<FieldError rulesStates={rulesStates} isDirty={isDirty} />
		</div>
	);
};

export default Checkbox;
