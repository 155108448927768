import { useMemo } from 'react';
import { ValidationRuleState } from '../../models/Rules';

const FieldError = ({
	rulesStates,
	isDirty,
}: {
	rulesStates: ValidationRuleState[];
	isDirty: boolean;
}) => {
	const errors = useMemo(() => {
		return Array.from(new Set(rulesStates.map((rule) => rule.error)));
	}, [rulesStates]);

	return (
		<div className="flex h-auto w-full flex-col items-center justify-start ">
			{rulesStates.length !== 0 &&
				errors.map((error, index) => {
					return (
						<span
							key={index}
							className={`animate-fadein font-bold text-red-600 ${
								isDirty ? '' : 'invisible'
							}`}>
							{error}
						</span>
					);
				})}
		</div>
	);
};

export default FieldError;
