import { useCallback, useState } from 'react';
import {
	ValidationRule,
	ValidationRuleState,
	VisibilityRule,
	VisibilityRuleState,
} from '../models/Rules';
import { useOperator } from './useOperator';

export function useVisibilityRules(visibilityRules: VisibilityRule[]) {
	const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);
	const { handleOperator } = useOperator();

	const handleVisibility = (ruleStates: VisibilityRuleState[]) => {
		const visRulesStates = ruleStates.map(
			(ruleState, index): VisibilityRuleState => {
				const { valueToCompare } = ruleState;
				const { FieldValue, Operator } = visibilityRules[index];

				return {
					...ruleState,
					result: handleOperator(
						valueToCompare,
						FieldValue,
						Operator
					),
				};
			}
		);
		setIsVisible(visRulesStates.every((rule) => rule.result));

		return visRulesStates;
	};

	return { isVisible: isVisible, handleVisibility };
}

export function useValidationRules(validationRules: ValidationRule[]) {
	const [isValid, setIsValid] = useState<boolean>(
		validationRules.length ? false : true
	);
	const [rulesStates, setRulesStates] = useState<ValidationRuleState[]>([]);
	const { handleOperator } = useOperator();

	const handleValidations = useCallback(
		(compareValue: string | number) => {
			const valRulesStates = validationRules.map(
				(valRule: ValidationRule): ValidationRuleState => {
					const { Compare, ErrorMessage, Operator, Value } = valRule;
					let result = false;
					if (
						Compare === 'Length' &&
						typeof compareValue === 'string'
					) {
						let intValue = Number.parseInt(Value) ?? null;
						if (intValue === null) {
							return {
								result,
								error: 'There has been an error!',
							};
						}
						result = handleOperator(
							compareValue.length,
							intValue,
							Operator
						);
					} else if (typeof compareValue === 'number') {
						result = handleOperator(
							compareValue,
							Number.parseInt(Value),
							Operator
						);
					} else if (typeof compareValue === 'string') {
						result = handleOperator(compareValue, Value, Operator);
					}
					return { result, error: !result ? ErrorMessage : '' };
				}
			);

			setIsValid(valRulesStates.every((rule) => rule.result));
			setRulesStates([...valRulesStates]);
		},
		[handleOperator, validationRules]
	);

	return { isValid, handleValidations, rulesStates };
}
