import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldComponentProps } from '../../../models/Field';

const ProgressBar = ({ field, dispatch }: FieldComponentProps) => {
	const [width, setWidth] = useState<number>(0);

	const intervalref = useRef<number | null>(null);

	const timer_partial = 100 / (field.Duration ?? 1);

	// Stop the interval
	const stopInterval = () => {
		if (intervalref.current) {
			window.clearInterval(intervalref.current);
			intervalref.current = null;
		}
	};

	useEffect(() => {
		if (width >= 100 + timer_partial) {
			stopInterval();
			dispatch({ action: 'next-step', data: {} });
		}
	}, [dispatch, timer_partial, width]);

	// Use the useEffect hook to cleanup the interval when the component unmounts
	useEffect(() => {
		// Start the interval
		const startInterval = () => {
			if (intervalref.current !== null) return;
			intervalref.current = window.setInterval(() => {
				setWidth((width) => {
					if (width >= 100 + timer_partial) {
						return width;
					}
					return width + timer_partial;
				});
			}, 1000);
		};

		startInterval();
		// here's the cleanup function
		return () => {
			if (intervalref.current !== null) {
				window.clearInterval(intervalref.current);
			}
		};
	}, [timer_partial]);

	return (
		<div className="progress-bar h-[40px] w-[400px] overflow-hidden rounded-lg bg-white">
			<div
				className="progress h-full bg-red-500 transition-[width] duration-1000 ease-linear"
				style={{ width: `${width}%` }}></div>
		</div>
	);
};

export default ProgressBar;
