import { useState, useEffect } from 'react';
import './App.css';
import { Survey } from './components/survey/survey';
import { SurveyProvider } from './context/surveyContext';
import { SurveyProps } from './models/Survey';
import { SURVEY_EXAMPLE } from './mock/survey';

function App() {
	const [initSurvey, setInitSurvey] = useState<SurveyProps>();

	useEffect(() => {
		const fetchSurvey = async () => {
			const res = await fetch(
				`https://vimacom-survey-backend.wiselyst.cloud/api/quiz-config/${window.location.host}`
			);
			const survey: SurveyProps = await res.json();
			// const survey = SURVEY_EXAMPLE;
			console.log(survey);

			setInitSurvey(survey);
			document.title = survey.Title;
		};

		fetchSurvey();
	}, []);

	return (
		<div className="survey bg-blue">
			<SurveyProvider>
				{initSurvey && <Survey {...initSurvey} />}
			</SurveyProvider>
		</div>
	);
}

export default App;
