import { Dispatch } from 'react';
import { ContextAction } from '../context/surveyContext';
import { ValidationRule, VisibilityRule, VisibilityRuleState } from './Rules';

export interface FieldProps {
	id: number;
	Label: string;
	Name: string; // (field name to send backend value)
	FieldType: FieldType; // (see Field Types FIELD.md)
	ValidationRules: ValidationRule[]; // (see VALIDATION RULE.md)
	VisibilityRules: VisibilityRule[];
	Required?: boolean;
	FieldOptions: FieldOption[]; // (used by Options, Checkbox)
	Content?: string; // Content (used by FieldType StaticText, contains HTML)
	Duration?: number; // (used by FieldType ProgressBar)
	Placeholders?: string[];
}

export interface FieldOption {
	Label: string;
	Value: string;
}

export enum FieldType {
	FullName = 'FullName',
	FreeText = 'FreeText',
	Mobile = 'Mobile',
	Checkbox = 'Checkbox',
	Options = 'Options',
	Email = 'Email',
	Number = 'Number',
	ButtonNext = 'ButtonNext',
	ButtonFinish = 'ButtonFinish',
	StaticText = 'StaticText',
	ProgressBar = 'ProgressBar',
	Cap = 'Cap',
}

export interface FieldState {
	name: string;
	value: number | string | string[];
	isValid: boolean;
	isVisible: boolean;
	required: boolean;
	isEmpty: boolean;
	visibilityRules: VisibilityRuleState[];
}

// TODO: Better name
export interface FieldComponentProps {
	field: FieldProps;
	dispatch: Dispatch<ContextAction>;
}
