import { FieldComponentProps } from '../../../models/Field';

const StaticText = ({ field, dispatch }: FieldComponentProps) => {
	return (
		<div className="field-content-container">
			<p className="field__content">{field.Content}</p>
		</div>
	);
};

export default StaticText;
