import { Dispatch, createContext, useMemo, useReducer } from 'react';
import { SurveyState } from '../models/Survey';
import { StepProps, StepState } from '../models/Step';
import { FieldProps, FieldState } from '../models/Field';
import { VisibilityRuleState } from '../models/Rules';

const SURVEY_STATE_EXAMPLE: SurveyState = {
	steps: [],
	currentStep: 0,
};

export interface ContextAction {
	action: string;
	data: any;
}

export const SurveyContext = createContext<SurveyState>(SURVEY_STATE_EXAMPLE);

export const SurveyDispatchContext = createContext<Dispatch<ContextAction>>(
	() => {
		return {};
	}
);

export function SurveyProvider({ children }: { children: any }) {
	const [survey, dispatch] = useReducer(surveyReducer, SURVEY_STATE_EXAMPLE);

	const surveyState = useMemo(
		() => ({
			...survey,
		}),
		[survey]
	);

	return (
		<SurveyContext.Provider value={surveyState}>
			<SurveyDispatchContext.Provider value={dispatch}>
				{children}
			</SurveyDispatchContext.Provider>
		</SurveyContext.Provider>
	);

	function initFields(fields: FieldProps[]): FieldState[] {
		const fieldsStates = fields.map((field: FieldProps): FieldState => {
			return {
				name: field.Name,
				value: '',
				isValid: field.ValidationRules.length ? false : true,
				required: field.Required !== null ? field.Required : false,
				isVisible: field.VisibilityRules.length ? false : true,
				isEmpty: true,
				visibilityRules: field.VisibilityRules.map((visRule) => {
					return {
						FieldName: visRule.FieldName,
						result: field.VisibilityRules.length ? false : true,
						valueToCompare: '',
					};
				}),
			} as FieldState;
		});
		return [...fieldsStates];
	}

	function initSteps(steps: StepProps[]): StepState[] {
		const stepsStates = steps.map((step: StepProps) => {
			return {
				isVisible: step.VisibilityRules?.length ? false : true,
				isValid: false,
				fields: initFields(step.Fields),
				visibilityRulesStates: step.VisibilityRules.map((rule) => {
					return {
						FieldName: rule.FieldName,
						result: step.VisibilityRules?.length ? false : true,
						valueToCompare: '',
					} as VisibilityRuleState;
				}),
			} as StepState;
		});
		return [...stepsStates];
	}

	function updateFieldVisibility(
		state: SurveyState,
		data: { isVisible: boolean; stepIndex: number; fieldIndex: number }
	) {
		const { fieldIndex, isVisible, stepIndex } = data;
		return {
			...state,
			steps: state.steps.map((step, step_index) => {
				if (step_index === stepIndex) {
					return {
						...step,
						fields: step.fields.map((field, field_index) => {
							if (field_index === fieldIndex) {
								return {
									...field,
									isVisible,
								};
							}
							return field;
						}),
					};
				}
				return step;
			}),
		};
	}

	function updateFieldValidation(
		state: SurveyState,
		data: { isValid: boolean; name: string }
	): SurveyState {
		const { isValid, name } = data;
		return {
			...state,
			steps: state.steps.map((step: StepState, index) => {
				if (index === state.currentStep) {
					return {
						...step,
						fields: state.steps[state.currentStep].fields.map(
							(field) => {
								if (field.name === name) {
									return {
										...field,
										isValid,
									};
								}
								return field;
							}
						),
					};
				}
				return step;
			}),
		};
	}

	function updateFieldValue(
		state: SurveyState,
		data: {
			name: string;
			value: string | number | string[];
			isEmpty: boolean;
		}
	): SurveyState {
		const { name, value, isEmpty } = data;
		return {
			...state,
			steps: state.steps.map((step) => {
				return {
					...step,
					visibilityRulesStates: step.visibilityRulesStates.map(
						(visRule) => {
							if (visRule.FieldName === name) {
								return {
									...visRule,
									valueToCompare: value,
								};
							}
							return visRule;
						}
					),
					fields: step.fields.map((field) => {
						return {
							...field,
							value: field.name === name ? value : field.value,
							isEmpty:
								field.name === name ? isEmpty : field.isEmpty,
							visibilityRules: field.visibilityRules.map(
								(visRule) => {
									if (visRule.FieldName === name) {
										return {
											...visRule,
											valueToCompare: value,
										};
									}
									return visRule;
								}
							),
						} as FieldState;
					}),
				};
			}) as StepState[],
		};
	}

	function nextStep(state: SurveyState): SurveyState {
		if (!state.steps[state.currentStep].isValid) {
			return state;
		}

		return {
			...state,
			currentStep: state.currentStep + 1,
		};
	}

	function updateStepVisibility(
		state: SurveyState,
		data: { isVisible: boolean | undefined; stepIndex: number }
	): SurveyState {
		const { isVisible: isStepVisible, stepIndex } = data;
		if (!isStepVisible && isStepVisible !== undefined) {
			return nextStep(state);
		}
		return {
			...state,
			steps: state.steps.map((step, index) => {
				if (index === stepIndex) {
					return {
						...step,
						isVisible:
							isStepVisible !== undefined ? isStepVisible : false,
					};
				}
				return step;
			}),
		};
	}

	function updateStepValidation(
		state: SurveyState,
		data: { isValid: boolean; stepIndex: number }
	): SurveyState {
		const { isValid: isStepValid, stepIndex } = data;
		return {
			...state,
			steps: state.steps.map((step, index) => {
				if (index === stepIndex) {
					return {
						...step,
						isValid: isStepValid,
					};
				}
				return step;
			}),
		};
	}

	function endSurvey(
		state: SurveyState,
		data: { survey: StepState[] }
	): SurveyState {
		console.log(data);
		return state;
	}

	function surveyReducer(
		state: SurveyState,
		contextAction: ContextAction
	): SurveyState {
		const { action, data } = contextAction;
		switch (action) {
			case 'update-field-value':
				return updateFieldValue(state, data);
			case 'init-survey-state':
				const { survey } = data;
				return {
					steps: initSteps(survey.Steps),
					currentStep: 0,
				};
			case 'update-step-validation':
				return updateStepValidation(state, data);
			case 'next-step':
				return nextStep(state);
			case 'update-step-visibility':
				return updateStepVisibility(state, data);
			case 'update-field-validation':
				return updateFieldValidation(state, data);
			case 'update-field-visibility':
				return updateFieldVisibility(state, data);
			case 'end-survey':
				return endSurvey(state, { survey: state.steps });
			default:
				return state;
		}
	}
}
