import { useCallback } from 'react';
import { Operator } from '../models/Operator';

export function useOperator() {
	const executeOperator = (
		value1: string | number,
		value2: string | number,
		operator: Operator
	) => {
		if (typeof value1 !== typeof value2) return false;
		switch (operator) {
			case Operator.Equal:
				return value1 === value2;
			case Operator.NotEqual:
				return value1 !== value2;
			case Operator.GreaterThan:
				return value1 > value2;
			case Operator.LessThan:
				return value1 < value2;
			case Operator.GreaterThanOrEqualTo:
				return value1 >= value2;
			case Operator.LessThanOrEqualTo:
				return value1 <= value2;
			case Operator.Regex: // value2 is the regex
				const regex = new RegExp(value2.toString());
				return regex.test(value1.toString());
			default:
				return false;
		}
	};

	const handleOperator = useCallback(
		(
			value1: string | number | string[],
			value2: string | number,
			operator: Operator
		) => {
			const handleNumber = (
				compareValue: number,
				valueToCompare: number,
				operator: Operator
			) => {
				return executeOperator(compareValue, valueToCompare, operator);
			};

			const handleString = (
				compareValue: string,
				valueToCompare: string,
				operator: Operator
			) => {
				return executeOperator(compareValue, valueToCompare, operator);
			};

			const handleArray = (
				array: string[],
				valueToCompare: string,
				operator: Operator
			) => {
				return array.every((compareValue) => {
					return executeOperator(
						compareValue,
						valueToCompare,
						operator
					);
				});
			};

			switch (typeof value1) {
				case 'string':
					return handleString(
						value1,
						value2?.toString() ?? '',
						operator
					);
				case 'number':
					return handleNumber(
						value1,
						Number.parseInt(value2?.toString() ?? '') ?? 0,
						operator
					);
				case 'object':
					if (Array.isArray(value1)) {
						return handleArray(
							value1,
							value2?.toString() ?? '',
							operator
						);
					}
					break;
			}
			return false;
		},
		[]
	);

	return {
		handleOperator,
	};
}
