import { useEffect, useMemo } from 'react';
import { useInput } from '../../../hooks/useInput';
import { FieldComponentProps } from '../../../models/Field';
import { useValidationRules } from '../../../hooks/useRule';
import FieldError from '../../fieldError/fieldError';
import { ValidationRule } from '../../../models/Rules';
import { Operator } from '../../../models/Operator';

const FULLNAME_VAL_RULES: ValidationRule[] = [
	{
		Compare: 'Length',
		ErrorMessage: 'Sia nome che cognome devono contenere almeno 3 lettere!',
		Operator: Operator.GreaterThanOrEqualTo,
		Value: '3',
	},
];

const FullName = ({ field, dispatch }: FieldComponentProps) => {
	const firstName = useInput('');
	const lastName = useInput('');

	const validation_rules = useMemo(
		() => field.ValidationRules.concat(FULLNAME_VAL_RULES),
		[field.ValidationRules]
	);

	const {
		isValid: isFirstNameValid,
		handleValidations: handleFirstNameValidations,
		rulesStates: firstNameRulesStates,
	} = useValidationRules(validation_rules);

	const {
		isValid: isLastNameValid,
		handleValidations: handleLastNameValidations,
		rulesStates: lastNameRulesStates,
	} = useValidationRules(validation_rules);

	useEffect(() => {
		handleLastNameValidations(lastName.value);
	}, [handleLastNameValidations, lastName.value]);

	useEffect(() => {
		handleFirstNameValidations(firstName.value);
	}, [firstName.value, handleFirstNameValidations]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				value: [
					firstName.value + ' ' + lastName.value,
					firstName.value,
					lastName.value,
				],
				name: field.Name,
				isEmpty: firstName.value === '' && lastName.value === '',
			},
		});
	}, [dispatch, field.Name, firstName.value, lastName.value]);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				name: field.Name,
				isValid: isLastNameValid && isFirstNameValid,
			},
		});
	}, [dispatch, field.Name, isFirstNameValid, isLastNameValid]);

	return (
		<div className="flex w-full flex-col items-center justify-center gap-y-4">
			<h2 className="field-label">{field.Label}</h2>
			<div className="field-input-container">
				<input
					className={`field__input ${
						(firstName.isDirty || lastName.isDirty) &&
						!isFirstNameValid
							? 'field__input--error'
							: ''
					}`}
					name="firstName"
					type="text"
					placeholder={
						field.Placeholders?.length
							? field.Placeholders[0]
							: 'Nome'
					}
					value={firstName.value}
					onChange={firstName.onChange}
				/>
				<input
					className={`field__input ${
						(firstName.isDirty || lastName.isDirty) &&
						!isLastNameValid
							? 'field__input--error'
							: ''
					}`}
					name="lastName"
					type="text"
					placeholder={
						field.Placeholders?.length
							? field.Placeholders[1]
							: 'Cognome'
					}
					value={lastName.value}
					onChange={lastName.onChange}
				/>
			</div>
			<FieldError
				isDirty={firstName.isDirty || lastName.isDirty}
				rulesStates={[...firstNameRulesStates, ...lastNameRulesStates]}
			/>
		</div>
	);
};

export default FullName;
