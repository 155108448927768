import { useCallback, useContext, useEffect } from 'react';
import Step from '../step/step';
import {
	SurveyContext,
	SurveyDispatchContext,
} from '../../context/surveyContext';
import { SurveyProps } from '../../models/Survey';
import TagManager from 'react-gtm-module';

export const Survey = ({
	id,
	Title,
	createdAt,
	updatedAt,
	publishedAt,
	Domain,
	Steps,
	HeaderHtml,
	IdGoogleTagManager,
	FooterHtml,
}: SurveyProps) => {
	const surveyState = useContext(SurveyContext);
	const dispatch = useContext(SurveyDispatchContext);

	const progressBarStyle = {
		width: `${(100 / Steps.length) * (surveyState.currentStep + 1)}%`,
	};

	useEffect(() => {
		dispatch({
			action: 'init-survey-state',
			data: {
				survey: {
					id,
					Title,
					createdAt,
					updatedAt,
					publishedAt,
					Domain,
					Steps,
				},
			},
		});
	}, [Domain, Steps, Title, createdAt, dispatch, id, publishedAt, updatedAt]);

	useEffect(() => {
		if (IdGoogleTagManager) {
			const gtm = { gtmId: IdGoogleTagManager };
			TagManager.initialize(gtm);
		}
	}, [IdGoogleTagManager]);

	function handleSubmit(e: any) {
		e.preventDefault();
	}

	return (
		<div className="survey flex min-h-screen w-full flex-col">
			<header
				className="survey__header mb-[3.125rem] h-auto w-full bg-white"
				dangerouslySetInnerHTML={{ __html: HeaderHtml }}></header>
			<form
				className="survey__form flex w-full flex-1 flex-col flex-wrap items-center p-5"
				onSubmit={handleSubmit}>
				{Steps.length !== 0 &&
					surveyState.currentStep < surveyState.steps.length && (
						<Step
							step={Steps[surveyState.currentStep]}
							key={`step-${surveyState.currentStep}`}
							stepIndex={surveyState.currentStep}
						/>
					)}
			</form>
			<footer
				className="h-auto w-full border-t-[1px] border-white p-[5px] text-left text-sm text-gray-500"
				dangerouslySetInnerHTML={{ __html: FooterHtml }}></footer>
			<div className="survey-progress h-[10px] bg-white transition-[width] duration-500">
				<div
					key={'progress-bar'}
					className="h-full bg-red-500"
					style={progressBarStyle}></div>
			</div>
		</div>
	);
};
