import { useEffect, useMemo } from 'react';
import { useInput } from '../../../hooks/useInput';
import { FieldComponentProps } from '../../../models/Field';
import FieldError from '../../fieldError/fieldError';
import { useValidationRules } from '../../../hooks/useRule';
import { ValidationRule } from '../../../models/Rules';
import { Operator } from '../../../models/Operator';

const MOBILE_VAL_RULES: ValidationRule[] = [
	{
		Value: '^3\\d{8,9}$',
		Operator: Operator.Regex,
		Compare: 'Value',
		ErrorMessage:
			'Il numero di telefono deve iniziare con 3\nMin: 9, Max: 10',
	},
];

const Mobile = ({ field, dispatch }: FieldComponentProps) => {
	const mobile = useInput('');

	const validation_rules = useMemo(
		() => field.ValidationRules.concat(MOBILE_VAL_RULES),
		[field.ValidationRules]
	);

	const { handleValidations, isValid, rulesStates } =
		useValidationRules(validation_rules);

	useEffect(() => {
		handleValidations(mobile.value ?? '');
	}, [handleValidations, mobile.value]);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				name: field.Name,
				isValid,
			},
		});
	}, [isValid, dispatch, field.Name]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				name: field.Name,
				value: mobile.value,
				isEmpty: mobile.value === '',
			},
		});
	}, [mobile.value, dispatch, field.Name]);

	return (
		<div className="field-input-container">
			<label>{field.Label}</label>
			<input
				className={`field__input 
					${mobile.isDirty && !isValid ? 'field__input--error' : ''}`}
				type="tel"
				value={mobile.value}
				onChange={mobile.onChange}
			/>
			<FieldError rulesStates={rulesStates} isDirty={mobile.isDirty} />
		</div>
	);
};

export default Mobile;
