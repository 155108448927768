import { FieldComponentProps } from '../../../models/Field';

const ButtonFinish = ({ field, dispatch }: FieldComponentProps) => {
	function handleClick(e: any) {
		dispatch({ action: 'end-survey', data: {} });
	}

	return (
		<div className="field-button-container">
			<button className="field__button" onClick={handleClick}>
				{field.Label}
			</button>
		</div>
	);
};

export default ButtonFinish;
