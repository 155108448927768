import { useContext, useEffect, useMemo } from 'react';
import { StepProps } from '../../models/Step';
import Field from '../field/field';
import {
	SurveyContext,
	SurveyDispatchContext,
} from '../../context/surveyContext';
import { StepType as StepTypes } from '../../models/Step';
import { useVisibilityRules } from '../../hooks/useRule';
import './step.css';

const Step = ({
	step,
	stepIndex,
}: {
	step: StepProps;
	stepIndex: number;
}): JSX.Element => {
	const context = useContext(SurveyContext);

	const dispatch = useContext(SurveyDispatchContext);
	const { Fields, Title, StepType, VisibilityRules, RedirectTo } = step;

	const isStepValid = useMemo(
		() =>
			context.steps[stepIndex].fields.every((field) => {
				if (!field.name) return true; // [Button-next,  Button-finish, StaticText] do not have a Name so we return always true
				if (field.required && field.isValid) return true;
				if (!field.required && !field.isValid) return field.isEmpty;
				return field.isValid;
			}),
		[context.steps, stepIndex]
	);

	useEffect(() => {
		dispatch({
			action: 'update-step-validation',
			data: { isValid: isStepValid, stepIndex },
		});
	}, [dispatch, isStepValid, stepIndex]);

	const { handleVisibility, isVisible } = useVisibilityRules(VisibilityRules);

	const visRulesStates = context.steps[stepIndex].visibilityRulesStates;

	useEffect(() => {
		if (isVisible === undefined) return;
		dispatch({
			action: 'update-step-visibility',
			data: {
				isVisible,
				stepIndex,
			},
		});
	}, [dispatch, isVisible, stepIndex]);

	useEffect(() => {
		handleVisibility(visRulesStates);
	}, [handleVisibility, visRulesStates]);

	useEffect(() => {
		switch (StepType) {
			case StepTypes.Default:
				break;
			case StepTypes.Redirect:
				if (
					RedirectTo !== null &&
					isVisible !== undefined &&
					isVisible
				) {
					window.location.href = RedirectTo;
				}

				break;
			default:
				break;
		}
	}, [RedirectTo, StepType, isVisible]);

	return (
		<div className="step">
			<h1 className="step__title">{Title}</h1>
			<div className="step__field-container">
				{Fields.length !== 0 &&
					Fields?.map((field, index) => (
						<Field
							field={field}
							key={`field-${index}-${field.Name}`}
							stepIndex={stepIndex}
							fieldIndex={index}
						/>
					))}
			</div>
		</div>
	);
};

export default Step;
