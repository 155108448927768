import { useEffect, useMemo } from 'react';
import { FieldComponentProps } from '../../../models/Field';
import { useInput } from '../../../hooks/useInput';
import { useValidationRules } from '../../../hooks/useRule';
import FieldError from '../../fieldError/fieldError';
import { ValidationRule } from '../../../models/Rules';

const INPUT_NUMBER_VAL_RULES: ValidationRule[] = [];

const InputNumber = ({ field, dispatch }: FieldComponentProps) => {
	const number = useInput('');

	const validation_rules = useMemo(
		() => field.ValidationRules.concat(INPUT_NUMBER_VAL_RULES),
		[field.ValidationRules]
	);

	const { handleValidations, isValid, rulesStates } =
		useValidationRules(validation_rules);

	useEffect(() => {
		handleValidations(Number.parseInt(number.value) ?? NaN);
	}, [handleValidations, number.value]);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				isValid,
				name: field.Name,
			},
		});
	}, [dispatch, field.Name, isValid]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				name: field.Name,
				value: Number.parseInt(number.value) ?? NaN,
				isEmpty: number.value === '',
			},
		});
	}, [dispatch, field.Name, number.value]);

	return (
		<div className="field-input-container">
			<label>{field.Label}</label>
			<input
				className={`field__input 
					${number.isDirty && !isValid ? 'field__input--error' : ''}`}
				type="number"
				value={number.value}
				onChange={number.onChange}
			/>
			<FieldError rulesStates={rulesStates} isDirty={number.isDirty} />
		</div>
	);
};

export default InputNumber;
