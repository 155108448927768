import { useState, useEffect } from 'react';
import { useValidationRules } from '../../../hooks/useRule';
import { FieldComponentProps } from '../../../models/Field';

const Options = ({ field, dispatch }: FieldComponentProps) => {
	const { FieldOptions } = field;

	const [value, setValue] = useState<string>('');

	const handleOptionsSubmit = (e: any) => {
		e.preventDefault();
		setValue(e.target.dataset.value);
		dispatch({ action: 'next-step', data: {} });
	};

	const { handleValidations, isValid } = useValidationRules(
		field.ValidationRules
	);

	useEffect(() => {
		handleValidations(value);
	}, [handleValidations, value]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				name: field.Name,
				value,
				isEmpty: value === '',
			},
		});
	}, [dispatch, field.Name, value]);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				name: field.Name,
				isValid,
			},
		});
	}, [dispatch, field.Name, isValid]);

	return (
		<div className="field-button-container">
			{FieldOptions.map(({ Label, Value }, i) => (
				<button
					type="button"
					className="field__button"
					onClick={handleOptionsSubmit}
					data-value={Value}
					key={`option-${i}`}>
					{Label}
				</button>
			))}
		</div>
	);
};

export default Options;
