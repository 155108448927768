import { useEffect } from 'react';
import { useInput } from '../../../hooks/useInput';
import { FieldComponentProps } from '../../../models/Field';
import { useValidationRules } from '../../../hooks/useRule';
import FieldError from '../../fieldError/fieldError';

const Text = ({ field, dispatch }: FieldComponentProps) => {
	const text = useInput('');

	const { isValid, rulesStates, handleValidations } = useValidationRules(
		field.ValidationRules
	);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				isValid,
				name: field.Name,
			},
		});
	}, [dispatch, field.Name, isValid]);

	useEffect(() => {
		handleValidations(text.value);
	}, [handleValidations, text.value]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				value: text.value,
				name: field.Name,
				isEmpty: text.value === '',
			},
		});
	}, [dispatch, field.Name, handleValidations, text.value]);

	return (
		<div className="field-input-container">
			<input
				className={`field__input ${
					text.isDirty && !isValid ? 'field__input--error' : ''
				}`}
				type="text"
				onChange={text.onChange}
				value={text.value}
			/>
			<FieldError rulesStates={rulesStates} isDirty={text.isDirty} />
		</div>
	);
};

export default Text;
