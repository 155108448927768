import { useEffect, useMemo } from 'react';
import { useInput } from '../../../hooks/useInput';
import { useValidationRules } from '../../../hooks/useRule';
import { FieldComponentProps } from '../../../models/Field';
import FieldError from '../../fieldError/fieldError';
import { Operator } from '../../../models/Operator';
import { ValidationRule } from '../../../models/Rules';

const EMAIL_VAL_RULES: ValidationRule[] = [
	{
		Value: '^([\\w\\-]+(?:\\.[\\w\\-]+)*)@((?:[\\w\\-]+\\.)*\\w[\\w\\-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2})?)$',
		Operator: Operator.Regex,
		Compare: 'Value',
		ErrorMessage: 'La mail é invalida',
	},
];

const Email = ({ field, dispatch }: FieldComponentProps) => {
	const email = useInput('');

	const validation_rules = useMemo(() => {
		return field.ValidationRules.concat(EMAIL_VAL_RULES);
	}, [field.ValidationRules]);

	const { handleValidations, isValid, rulesStates } =
		useValidationRules(validation_rules);

	useEffect(() => {
		dispatch({
			action: 'update-field-validation',
			data: {
				name: field.Name,
				isValid,
			},
		});
	}, [dispatch, field.Name, isValid]);

	useEffect(() => {
		dispatch({
			action: 'update-field-value',
			data: {
				name: field.Name,
				value: email.value,
				isEmpty: email.value === '',
			},
		});
	}, [dispatch, email.value, field.Name]);

	useEffect(() => {
		handleValidations(email.value);
	}, [email.value, handleValidations]);

	return (
		<div className="field-input-container">
			<label htmlFor={field.Name}>{field.Label}</label>
			<input
				className={`field__input ${
					!isValid && email.isDirty ? 'field__input--error' : ''
				}`}
				type="email"
				name={field.Name}
				id={field.Name}
				onChange={email.onChange}
				value={email.value}
			/>
			<FieldError rulesStates={rulesStates} isDirty={email.isDirty} />
		</div>
	);
};

export default Email;
