import { FieldComponentProps } from '../../../models/Field';

const ButtonNext = ({ field, dispatch }: FieldComponentProps) => {
	const handleClick = (e: any) => {
		e.preventDefault();
		dispatch({ action: 'next-step', data: {} });
	};

	return (
		<button
			type="button"
			className="field__button max-w-max"
			onClick={handleClick}>
			{field.Label + ' →'}
		</button>
	);
};

export default ButtonNext;
